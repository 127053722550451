import { useEffect, useState } from 'react'

import api from '@/api'
import Main from '@/pages/Main/Main'

import './App.css'

function App() {
  const [formats, setFormats] = useState([])

  useEffect(() => {
    api.get('/api/event_format/event/m52_2024').then((response) => {
      if (response?.data?.values) {
        setFormats(response.data.values)
      }
    })
  }, [])

  return <Main formats={formats} />
}

export default App
